.listagem_titulo {
  font-size: 40px;
  font-weight: 800;
  letter-spacing: 0.41px;
  line-height: 50px;
  text-align: center;
}
.listagem_subtitulo {
  font-size: 19.69px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 25px;
  text-align: center;
  text-transform: uppercase;
}

.paragrafo {
  font-size: 17px;
  letter-spacing: 0;
  line-height: 27px;
  text-align: justify;
}

.titulo_reserva {
  color: #ffffff;
  font-size: 30px;
  font-weight: 800;
  letter-spacing: 0.23px;
  line-height: 38px;
  text-align: center;
  margin-top: -90px;
  z-index: 33;
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paragrafo a {
  text-decoration: underline;
}
.BannerInt {
  background-position: center 69px;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  .listagem_titulo {
    font-size: 28px;
    line-height: 35px;
  }
  .listagem_subtitulo {
    font-size: 13.78px;
    line-height: 18px;
  }
  .titulo_reserva {
    margin-top: -12vw;
  }
}

@media (max-width: 575px) {
  .titulo_reserva {
    font-size: 6vw;
    line-height: 5vw;
    margin-top: -9vw;
  }
  .titulo_reserva svg {
    display: none;
  }
}
